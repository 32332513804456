import haversine from "new-point-haversine";
import echarts from "echarts";
import moment from "moment";

const mapcenter = [114.159612, 22.373160];

//Calculate map boundary
const { lowerLat, upperLat } = haversine.getLatitudeBounds(
    mapcenter[1],
    40,
    "km"
  );
  
  const { lowerLong, upperLong } = haversine.getLongitudeBounds(
    mapcenter[1],
    mapcenter[0],
    50,
    "km"
  );


//Optional mapbox token (for using mapbox tile server to get Akirakan Lite Style)
mapboxgl.accessToken =
  "pk.eyJ1IjoidW5pZGVudGlmaWVkIiwiYSI6ImNpeHB1MzYxdDA5djgzMWw5cnV5bzd0aHUifQ.kH_kOimuJVi0tuOzfoDI2g";

if (window.$map) window.$map.remove();
const map = (window.$map = new mapboxgl.Map({
  container: "map",
  maxBounds: [lowerLong, lowerLat, upperLong, upperLat],
  // style: "mapbox://styles/unidentified/ck3zw2kum05t11do5hqhs4jmo", //Akirakan Lite Style (optional)
  style: "https://api.maptiler.com/maps/884a5d89-3a33-4276-bb66-b0e2b8a8ebb0/style.json?key=q7mwu3iiAeubD5fzfI7C",
  // logoPosition: "top-right",
  maxZoom: 15,
  zoom: 11.5,
  renderWorldCopies: false,
  boxZoom: false,
  center: mapcenter,
  attributionControl: false
}));

